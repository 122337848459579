import { datadogLogs } from '@datadog/browser-logs';

const initializeLogger = () => {
    const ENV = process.env.REACT_APP_ENV || 'development';
    const DD_CLIENT_TOKEN = process.env.REACT_APP_DD_CLIENT_TOKEN;

    if (DD_CLIENT_TOKEN) {
        datadogLogs.init({
            clientToken: DD_CLIENT_TOKEN,
            site: 'us5.datadoghq.com',
            forwardErrorsToLogs: true,
            sessionSampleRate: 100,
            service: 'bimmatch-frontend',
            env: ENV,
            beforeSend: (log) => {
                log.env = ENV;
                log.service = 'bimmatch-frontend';
                return log;
            },
        });
    }
};

const LogLevelId = {
    debug: 1,
    info: 2,
    warn: 3,
    error: 4,
};

const LEVEL = process.env.REACT_APP_LOG_LEVEL || 'info';

const formatMeta = (meta = {}) => {
    if (meta?.error) {
        const error = meta.error;
        return {
            ...meta,
            error: {
                message: error?.message,
                stack: error?.stack,
                type: error?.constructor?.name,
                code: error?.code,
                statusCode: error?.statusCode,
            }
        };
    }
    return meta;
};

const Logger = {
    debug: (msg, meta = {}) => {
        if (LogLevelId.debug >= LogLevelId[LEVEL]) {
            console.debug(msg, formatMeta(meta));
            datadogLogs.logger.debug(msg, formatMeta(meta));
        }
    },

    info: (msg, meta = {}) => {
        if (LogLevelId.info >= LogLevelId[LEVEL]) {
            console.info(msg, formatMeta(meta));
            datadogLogs.logger.info(msg, formatMeta(meta));
        }
    },

    warn: (msg, meta = {}) => {
        if (LogLevelId.warn >= LogLevelId[LEVEL]) {
            console.warn(msg, formatMeta(meta));
            datadogLogs.logger.warn(msg, formatMeta(meta));
        }
    },

    error: (msg, meta = {}) => {
        if (LogLevelId.error >= LogLevelId[LEVEL]) {
            const formattedMeta = formatMeta(meta);
            console.error(msg, formattedMeta);
            datadogLogs.logger.error(msg, formattedMeta);
        }
    }
};

export { initializeLogger, Logger as default };