export const PERMISSIONS = {
  CREATE_PRODUCT_FROM_RVT: "create-product-from-rvt",
  CREATE_PRODUCT_FROM_RFA: "create-product-from-rfa",
  CREATE_ACC_PROJECT: "create-acc-project",
  CREATE_PROJECT_BUTTON_VIEW: "create-project-button-view", //handling visiblility of create project button
  EDIT_PROJECT: "edit-project",
  DELETE_PROJECT: "delete-project",
  CREATE_SPEC: "create-spec",
  DUPLICATE_SPEC: "duplicate-spec",
  SYNC_CHANGES_FROM_ACC: "sync-changes-from-acc",
  CONNECT_TO_ACC: "connect-to-acc", // has access to link org with acc account
  ORGANIZATION_DB: "organization-db",
  ANALYTICS: "analytics",
  EXPORT_BOM_TO_EXCEL : "export-bom-to-excel",
  CREATE_PRODUCT: "create-product",
  CREATE_BIM_PROJECT: "create-bim-project",
  BIM_FILE_UPLOAD: "bim-file-upload",

  // reserved key for limits for certain features
  LIMITS: "limits"
};

export const PAGE_ACCESS = {
  ANALYTICS: "analytics",
  PROJECTS: "projects",
  PRODUCTS: "products",
  PROJECT_DETAIL: "project-detail",
  PRODUCT_DETAIL: "product-detail",
  PERSONAL_SETTINGS: "personal-settings",
  ORGANIZATION_SETTINGS: "organization-settings",
  CREATE_PRODUCT: "create-product",
  UPDATE_PRODUCT: "update-product",
  ORGANIZATION_DB: "organization-db",
  TEAM: "team",
  SPECS: "specs",
}

export const USAGE_PERMISSIONS = {
  CREATE_ACC_PROJECT: "accProjects",
  CREATE_BIM_PROJECT: "bimProjects" ,
  CREATE_PRODUCT: "products",
  CREATE_PRODUCT_FROM_RFA: "rfaUploads",
  CREATE_PRODUCT_FROM_RVT: "rvtUploads",
  CREATE_SPEC: "specs"
}
