import { useState, useEffect } from "react";
import { LoadScript } from "../utils";
import Logger from "../logger";

const useLoadScript = ({ key = process.env.REACT_APP_GOOGLE_MAP_KEY } = {}) => {
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (!window?.initMap) {
      window.initMap = function () {
        // console.log('map loaded')
      };
    }
    LoadScript(
      `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap&libraries=geometry,places`
    )
      .then((isLoaded) => {
        // wait until "window.google.maps" object is getting created
        const checkValidity = () => {
          if (!(window.google && window.google.maps)) {
            setTimeout(checkValidity, 5);
          } else {
            setScriptLoaded(isLoaded);
          }
        };
        setTimeout(checkValidity, 5);
      })
      .catch((error) => {
        Logger.error('LoadScript error', {
          error,
          context: {
            key,
          }
        });
      });
  });

  return isScriptLoaded;
};

export default useLoadScript;
