import axios from "axios";
import { commonAuthHeaders } from "../auth/utils";
import Logger from "../logger";

const API_URL = `${process.env.REACT_APP_APIS_URL}/apis`;

const instance = axios.create({
  baseURL: API_URL,
  timeout: 3600000,
});

instance.interceptors.request.use(
  async (config) => {
    config.headers = await commonAuthHeaders()
    return config;
  },
  (error) => {
    Logger.error('axios request error', { error });
    // Do something with request error
    return Promise.reject(error);
  }
);

export const getAutodeskToken = async () => {
  return instance.get("/bim/token");
};

export const uploadFile = async ({ data }, { onUploadProgress, headers }) => {
  const res = await instance.post("/bim/upload-file", data, {
    onUploadProgress,
    headers,
  });
  return res;
};

export const createProject = async (
  { data },
  { onUploadProgress, headers, cancelToken }
) => {
  const res = await instance.post("/bim/create-project", data, {
    onUploadProgress,
    headers,
    cancelToken,
  });
  return res;
};

export const uploadProjectFile = async (
  { data },
  { onUploadProgress, headers }
) => {
  const res = await instance.post("/bim/project/upload", data, {
    onUploadProgress,
    headers,
  });
  return res;
};

export const generateBOM = async (projectId, viewId) => {
  const res = await instance.post(
    `/bom/project/generate-bom/${projectId}${viewId ? `?viewId=${viewId}` : ""}`
  );
  return res;
};

export const getProjectManifest = async ({ urn, region }) => {
  const result = await instance.get(`/bim/get/manifest/${urn}`, {
    params: {
      region,
    },
  });
  return result;
};

export const getDownloadUrl = (key) => {
  return `${API_URL}/custom/product/download?key=${key}`;
};

// Common function for handling file downloads
const downloadFileFromResponse = (data, fileName) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(downloadUrl);
};

// Generic file download function using instance
const downloadFileWithInstance = async (url, fileName, options = {}) => {
  try {
    const { data } = await instance.request({
      url,
      method: 'GET',
      responseType: 'blob',
      ...options
    });
    downloadFileFromResponse(data, fileName);
    return true;
  } catch (error) {
    Logger.error('download error', { error, context: { url, fileName } });
    return false;
  }
};

export const downloadFile = async (key) => {
  return downloadFileWithInstance(
    getDownloadUrl(key),
    getFileNameFromAwsKey(key)
  );
};

export const getFileNameFromAwsKey = (key) => key ? key.split("/").slice(-1)[0] : key;

export const downloadMultipleFiles = async (keys) => {
  const validKeys = keys.filter(Boolean);
  validKeys.forEach(key => downloadFile(key));
};

// Excel export functions using common download
export const bomExcelExport = (projectId, detailed = false) => {
  const fileName = `${projectId}-${detailed ? "detailed" : "lean"}.xlsx`;
  const url = `${API_URL}/bom/project/excel/${projectId}${detailed ? "/detailed" : ""}`;
  return downloadFileWithInstance(url, fileName);
};

export const privateCatalogExcelExport = (orgId) => {
  return downloadFileWithInstance(
    `${API_URL}/cms/export/catalog/excel`,
    `${orgId}.xlsx`
  );
};

export const privateCatalogProductExcelExport = (productId) => {
  return downloadFileWithInstance(
    `${API_URL}/cms/export/product/${productId}/excel`,
    `${productId}.xlsx`
  );
};

export const sandboxExcelExport = (projectId, viewId) => {
  return downloadFileWithInstance(
    `${API_URL}/house/${projectId}/${viewId}/materials`,
    `${projectId}.xlsx`
  );
};

export const getGeoCordinates = () => {
  return instance.get(`${API_URL}/custom/location`);
};

export const getMixpanelEventData = ({
  projectId,
  from_date: fromDate,
  to_date: toDate,
  event,
  where,
}) => {
  let obj = {
    from_date: fromDate,
    to_date: toDate,
  };
  if (event) {
    obj.event = event;
  }
  if (where) {
    obj.where = where;
  }
  return instance.get(`/mixpanel/project/${projectId}`, {
    params: obj,
  });
};

export const fetchBomData = async (id, viewId, objectId = "ROOT") => {
  const { data } = await instance.get(`/house/${id}/bom`, {
    params: {
      viewId,
      objectId,
    },
  });
  return data;
};

export const openPdf = async (pdfFile = {}, pageNumber) => {
  try {
    const { data } = await instance.request({
      url: `/custom/pdf-viewer`,
      method: 'GET',
      responseType: 'blob',
      params: {
        bucketName: pdfFile.Bucket,
        key: pdfFile.Key
      }
    });

    const blobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
    let url = blobUrl;
    if (pageNumber) {
      url += `#page=${pageNumber}`;
    }
    window.open(url, '_blank');
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    Logger.error('download error', { error });
  }
};
