import React from "react";
import ReactDOM from "react-dom";
import mixpanel from "mixpanel-browser";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import App from "./App";
import { RecoilRoot } from "recoil";
import { initializeLogger } from "./common/logger";

if (process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "qa") {
  initializeLogger();
}

(process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "qa") &&
  process.env.REACT_APP_TRACKING_ID &&
  mixpanel.init(process.env.REACT_APP_TRACKING_ID, {
    enable_collect_everything: true,
  });

ReactDOM.render(
  <RecoilRoot>
    {" "}
    <App />{" "}
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
process.env.REACT_APP_ENV === "production" && serviceWorker.register();
