export const defaultBomObjectMetadata = {
    childrenLength: 0,
    environmental: 0,
    economic: 0,
    social: 0,
    carbonEmission: 0,
    price: 0,
    eir: 0,
    eirNotUpdated: 0,
    geometryFile: 0,
    geometryFileNotUpdated: 0,
    archiveObjects: 0,
    template: 0,
    templateNotUpdated: 0
};