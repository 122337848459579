export const COLOR = {
    DARK_GRAY: "#cccccc",
    WHITE: "#ffffff",
    SKY_BLUE: "#379BBA",
    LIGHT_GREEN: "#73BA37",
    BLUE_HIGHLIGHT: "#376BBA",
    BLACK: "#151515",
    TRANSPARENT: "rgba(0,0,0,0)",

    // colors used for theming matchbar chips
    PALE_YELLOW : "#fff2b2",
    PALE_BLUE: "#bbedff",
    PALE_GREEN: "#d9f2e0"
}

export const STANDARD_REPORT_COLORS = {
    EIR_UPDATED: "#37C446",
    EIR_NOT_UPDATED: "#95DB9C",
    GEOMETRY_FILE_UPDATED: "#517EF3",
    GEOMETRY_FILE_NOT_UPDATED: "#9DB1E5",
    DELETED: "#FF0000",
    UNDEFINED: "#CCCCCC",
    TEMPLATE_UPDATED: "#D14AA2",
    TEMPLATE_NOT_UPDATED: "#E09CC8",
}