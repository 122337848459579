import { useState, useEffect } from "react";
import { getAutodeskToken } from "../common/services";
import { LoadScript, LoadStyle } from "../common/utils";
import Logger from "../common/logger";

const timeout = 100;

const useInitializeForgeViewer = () => {
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        if (!isReady) {
            const promises = [];
            promises.push(
                LoadStyle(
                    `https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css`
                )
            );
            promises.push(
                LoadScript(
                    `https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js`
                )
            );
            Promise.all(promises)
                .then((isLoaded) => {
                    const checkValidity = () => {
                        if (!(window.Autodesk && window.Autodesk.Viewing)) {
                            setTimeout(checkValidity, timeout);
                        } else {
                            const options = {
                                env: "AutodeskProduction",
                                api: "derivativeV2", // for models uploaded to EMEA change this option to 'derivativeV2_EU'
                                getAccessToken: function (onTokenReady) {
                                    getAutodeskToken()
                                        .then(({ data }) => {
                                            onTokenReady(data.access_token, data.expires_in);
                                        })
                                        .catch((error) => {
                                            Logger.error('getAutodeskToken error', { error });
                                        });
                                },
                            };
                            window.Autodesk.Viewing.Initializer(options, () => {
                                console.log("Autodesk Viewer Successfully Initialized");
                                setReady(true);
                            });
                        }
                    };
                    setTimeout(checkValidity, timeout);
                })
                .catch((error) => {
                    Logger.error('useInitializeForgeViewer error', { error });
                });
        }
    });

    return isReady;
};

export default useInitializeForgeViewer;